.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-control {
  cursor: default !important ;
}
.custom-control-label:before {
  background-color: #fff;
  border: 1px solid #356859;
  top: 0.5rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #356859;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 0.5rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #356859 !important;
  background-color: #356859 !important;
}
input[type="radio"] {
  /* width: 15px;
  height: 15px; */
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #356859;
}
input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #356859;
}

input[type="radio"]:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #356859;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
.jUxVQN input {
  font-family :"IBM Plex Sans"
}
.gFDbrB{
  border-color: "#356859" !important;
  box-shadow: none !important;
}
.SNVCN{
  border-color: "#356859" !important;
  box-shadow: none !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #C8E6C9 !important;
  color:#303030 !important;
  font-family: "IBM Plex Sans" !important;
  font-size: 16px !important;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #FFCBD3 !important;
  color:#303030 !important;
  font-family: "IBM Plex Sans" !important;
  font-size: 16px !important;
}

.SNVCN{
  border-color:none !important;
  border:none !important;
  background-color: initial !important;
  box-shadow: none !important;
}
